<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div id="custom-modal-body" class="custom-modal-body shadow position-relative">
      <div class="
            p-3
            bg-primary
            lead
            text-center
            fw-medium
            text-light
            position-relative
          ">
        <button class="btn py-0 px-4 h-100 position-absolute" style="left: 0; top: 0" @click="deleteResource()"
          v-if="rsc">
          <i class="fa fa-trash-alt text-danger" />
        </button>
        {{ rsc ? "Edit" : "Add" }} resource
        <button class="btn py-0 px-4 h-100 position-absolute" style="right: 0; top: 0" @click="closePreview()">
          <i class="fa fa-times text-light" />
        </button>
      </div>
      <div class="p-4">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="title" class="form-label">
                Title<sup class="text-danger">*</sup>
              </label>
              <input type="text" class="form-control" id="title" v-model="models.title" />
            </div>
          </div>
          <div class="col">
            <div class="mb-5 mb-md-3 position-relative">
              <label for="thumbnail" class="form-label">Thumbnail</label>
              <div class="ratio ratio-1x1 rounded shadow-sm border mx-auto" style="width: 220px">
                <button class="
                      btn
                      bg-light
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                      p-0
                    " @click="selectThumbnail()">
                  <template v-if="models.thumbnail || thumbnailPreview">
                    <img v-if="thumbnailPreview" class="image-fit mh-100" :src="thumbnailPreview" />
                    <img v-else-if="models.thumbnail" class="image-fit mh-100"
                      :src="Helper.formatMediaUrl(models.thumbnail)" />
                    <button class="
                          btn btn-link
                          text-danger text-decoration-none
                          position-absolute
                        " style="bottom: -35px" @click.stop="clearPreviewThumbnail()">
                      <i class="fas fa-times me-2" />Remove
                    </button>
                  </template>
                  <template v-else>
                    <i class="fas fa-plus mb-2" />
                    <small class="font-monospace text-center lh-sm">
                      Add file
                    </small>
                  </template>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label for="Link" class="form-label">
                Id or Link
              </label>
              <input type="url" class="form-control" id="link" v-model="models.url" placeholder="https://..." />
            </div>
          </div>
          <div class="col">
            <div class="mb-5 mb-md-3 position-relative">
              <label for="video" class="form-label">Video</label>
              <div class="ratio ratio-1x1 rounded shadow-sm border mx-auto" style="width: 220px">
                <button class="
                    btn
                    bg-light
                    d-flex
                    flex-column
                    justify-content-center
                    align-items-center
                    p-0
                  " @click="selectVideo()">
                  <template v-if="models.video || videoPreview">
                    <img v-if="videoPreview" class="image-fit mh-100" :src="videoPreview" />
                    <img v-else-if="models.video" class="image-fit mh-100" :src="Helper.formatMediaUrl(models.video)" />
                    <button class="
                        btn btn-link
                        text-danger text-decoration-none
                        position-absolute
                      " style="bottom: -35px" @click.stop="clearPreviewVideo()">
                      <i class="fas fa-times me-2" />Remove
                    </button>
                  </template>
                  <template v-else>
                    <i class="fas fa-plus mb-2" />
                    <small class="font-monospace text-center lh-sm">
                      Add file
                    </small>
                  </template>
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-4" />
        <div class="d-flex justify-content-between align-items-center">
          <button class="btn btn-light shadow" @click="closePreview()">
            Cancel
          </button>
          <button class="btn btn-primary" @click="submitChange()">
            <i class="fas fa-save me-2" />
            Submit
          </button>
        </div>
      </div>
      <loading-spinner class="text-light position-fixed" v-if="isLoading" />
    </div>
  </div>
</template>
  
<script>
import Swal from "sweetalert2";
import LoadingSpinner from "@/components/LoadingSpinner";
import SelectClass from "@/components/Modals/SelectClass";

export default {
  props: ["rsc"],
  components: {
    SelectClass,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      showSelectClass: false,
      thumbnailPreview: null,
      thumbnailInput: null,
      videoPreview: null,
      videoInput: null,
      models: {
        title: null,
        url: null,
        thumbnail: null,
        video: null,
      },
    };
  },
  computed: {
    formInvalid() {
      if (
        !this.models.title ||
        this.models.title.length < 1
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    selectThumbnail() {
      let input = document.createElement("input");

      input.type = "file";

      input.accept = "image/*,video/*,.pdf";

      input.onchange = (e) => this.thumbnailChanged(e);

      input.click();
    },
    clearPreviewThumbnail() {
      this.thumbnailInput = null;

      this.thumbnailPreview = null;

      this.models.thumbnail = null;
    },
    thumbnailChanged(e) {
      let file = e.target.files[0];

      this.thumbnailInput = file;

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (readerEvent) => {
        this.thumbnailPreview = readerEvent.target.result;
      };
    },
    selectVideo() {
      let input = document.createElement("input");

      input.type = "file";

      input.accept = "image/*,video/*,.pdf";

      input.onchange = (e) => this.videoChanged(e);

      input.click();
    },
    clearPreviewVideo() {
      this.videoInput = null;

      this.videoPreview = null;

      this.models.video = null;
    },
    videoChanged(e) {
      let file = e.target.files[0];

      this.videoInput = file;

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (readerEvent) => {
        this.videoPreview = readerEvent.target.result;
      };
    },
    deleteResource() {
      Swal.fire({
        title: "<h5 class='mb-0'>Delete this resource?</h5>",
        icon: "question",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "bg-danger",
        },
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.isLoading = true;

            return this.API.put(`featured-videos/${this.rsc._id}`, {
              status: "Deleted",
            })
              .catch((error) => { })
              .finally(() => {
                Swal.fire("Resource deleted", "", "success");

                this.$emit("delete", this.rsc);
              });
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
          }, 3000);
        });
    },
    closePreview(e) {
      this.$nextTick(() => {
        this.$emit("close", e);
      });
    },
    async submitChange() {
      if (this.formInvalid) {
        Swal.fire("", "Invalid form submitted", "error");
      } else {
        this.isLoading = true;

        let method = "post";

        let url = "featured-videos";

        let body = {};

        try {
          if (this.rsc) {
            if (this.thumbnailPreview) {
              let fileModels = {
                ref: "featured-video",
                refId: this.rsc._id,
                field: "thumbnail",
                files: this.thumbnailInput,
              };
              const formData = new FormData();

              Object.keys(fileModels).forEach((key) => {
                if (key != "files") {
                  formData.append(key, fileModels[key]);
                } else {
                  formData.append(
                    "files",
                    fileModels.files,
                    fileModels.files.name
                  );
                }
              });
              const { data } = await this.API.postForm("upload", formData);
              this.models.thumbnail = data;
            }

            if (this.videoPreview) {
              let fileModels = {
                ref: "featured-video",
                refId: this.rsc._id,
                field: "video",
                files: this.videoInput,
              };
              const formData = new FormData();

              Object.keys(fileModels).forEach((key) => {
                if (key != "files") {
                  formData.append(key, fileModels[key]);
                } else {
                  formData.append(
                    "files",
                    fileModels.files,
                    fileModels.files.name
                  );
                }
              });
              const { data } = await this.API.postForm("upload", formData);
              this.models.video = data;
            }

            method = "put";

            url += `/${this.rsc._id}`;

            body = { ...this.models };

            if (body.thumbnail) {
              body.thumbnail = body.thumbnail._id;
            }
          } else {
            if (this.thumbnailPreview) {
              const formData = new FormData();

              formData.append("files", this.thumbnailInput, this.thumbnailInput.name);

              const { data } = await this.API.postForm("upload", formData);

              this.models.thumbnail = data;
            }

            if (this.videoPreview) {
              const formData = new FormData();

              formData.append("files", this.videoInput, this.videoInput.name);

              const { data } = await this.API.postForm("upload", formData);

              this.models.video = data;
            }

            body = { ...this.models };
          }

          const { data } = await this.API[method](url, body);

          Swal.fire(
            `Resources ${this.rsc ? "updated" : "added"}`,
            "",
            "success"
          ).then(() => {
            if (this.rsc) {
              this.closePreview(data);
            } else {
              this.$emit("add", data);
            }
          });
        } catch (err) {
          Swal.fire(
            "Opps. An error occured",
            JSON.stringify(err.response.data),
            "error"
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
  async mounted() {
    document.getElementsByTagName("body")[0].classList.add("overflow-hidden");

    if (this.rsc) {
      for (const key in this.models) {
        if (Object.hasOwnProperty.call(this.models, key)) {
          this.models[key] = JSON.parse(
            JSON.stringify(this.rsc[key] || "")
          );
        }
      }
    }
  },
  beforeDestroy() {
    this.closePreview();

    document
      .getElementsByTagName("body")[0]
      .classList.remove("overflow-hidden");
  },
};
</script>