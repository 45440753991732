<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Memuatkan senarai bahan belajar
  </loading-spinner>
  <div v-else>
    <div v-if="showJuniorModule" class="p-3 bg-blue-dark text-light rounded shadow-sm border d-flex align-items-center">
      <button class="btn btn-sm py-0 me-3 lh-1" @click="resetModule()">
        <i class="fas fa-arrow-left text-light" />
      </button>
      <p class="mb-0 text-truncate fw-medium lh-1">
        {{ category  }}
      </p>
    </div>
    <div v-if="showJuniorModule" class="
          pb-3 pt-3
          d-flex
          justify-content-between
          align-items-center
          flex-column flex-sm-row
        ">
      <div>
        <span class="badge bg-dark shadow-sm py-2 fw-normal">
          Jumlah bahan belajar: {{ resources.length }}
        </span>
      </div>
    </div>
    <div class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        ">
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th class="pe-0">Bahan Belajar</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
      <div v-if="!showJuniorModule" class="list-group">
        <div @click="juniorModule('junior')" class="list-group-item pe-0">
          <div class="d-flex fw-bold flex-nowrap justify-content-between align-items-center w-100">
            <div class="flex-fill clickable ms-2">MODULE JUNIOR</div>
          </div>
        </div>
        <div @click="juniorModule('discoverer')" class="list-group-item pe-0">
          <div class="d-flex fw-bold flex-nowrap justify-content-between align-items-center w-100">
            <div class="flex-fill clickable ms-2">MODULE DISCOVERER</div>
          </div>
        </div>
        <div @click="openModule('explorer')" class="list-group-item pe-0">
          <div class="d-flex fw-bold flex-nowrap justify-content-between align-items-center w-100">
            <i v-if="!moduleDetail" class="fas fa-caret-right" />
            <i v-if="moduleDetail" class="fas fa-caret-down" />
            <div class="flex-fill clickable ms-2">MODULE EXPLORER</div>
          </div>
        </div>
        <div v-if="moduleDetail">
          <div @click="juniorModule('arduino')" class="list-group-item pe-0 ">
            <div class="d-flex flex-nowrap justify-content-between align-items-center w-100">
              <div class="flex-fill clickable ms-4">Arduino</div>
            </div>
          </div>
          <div @click="juniorModule('iot')" class="list-group-item pe-0">
            <div class="d-flex flex-nowrap justify-content-between align-items-center w-100">
              <div class="flex-fill clickable ms-4">IOT Projects</div>
            </div>
          </div>
        </div>
        <div @click="juniorModule('innovator')" class="list-group-item pe-0">
          <div class="d-flex fw-bold flex-nowrap justify-content-between align-items-center w-100">
            <div class="flex-fill clickable ms-2">MODULE INNOVATOR</div>
          </div>
        </div>
        <div @click="openModule('creator')" class="list-group-item pe-0">
          <div class="d-flex fw-bold flex-nowrap justify-content-between align-items-center w-100">
            <i v-if="!moduleDetail2" class="fas fa-caret-right" />
            <i v-if="moduleDetail2" class="fas fa-caret-down" />
            <div class="flex-fill clickable ms-2">MODULE CREATOR</div>
          </div>
        </div>
        <div v-if="moduleDetail2">
          <div @click="juniorModule('cyber')" class="list-group-item pe-0 ">
            <div class="d-flex flex-nowrap justify-content-between align-items-center w-100">
              <div class="flex-fill clickable ms-4">Cyber Security</div>
            </div>
          </div>
          <div @click="juniorModule('roblox')" class="list-group-item pe-0">
            <div class="d-flex flex-nowrap justify-content-between align-items-center w-100">
              <div class="flex-fill clickable ms-4">Roblox</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list-group">
        <div class="list-group-item pe-0" v-for="rsc in computedresources" :key="rsc._id">
          <div class="d-flex flex-nowrap justify-content-between align-items-center w-100">
            <div class="flex-fill">{{ rsc.title }}</div>
            <div class="px-2 flex-shrink-1 flex-grow-0">
              <button class="btn btn-primary py-0 px-2 mx-1" :class="{ 'semi-transparent no-hover': !rsc.file }"
                @click="currentMedia = rsc.file">
                <span class="badge bg-primary"> PDF </span>
              </button>
              <button class="btn bg-disabled py-0 px-3 mx-1" :class="{
                'semi-transparent no-hover': !rsc.youtube_link,
              }" @click="rsc.youtube_link ? viewResource(rsc, 'youtube') : ''">
                <img style="height: 13px; width: auto" src="@/assets/icons/youtube.png" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <media-viewer v-if="currentMedia" :url="this.Helper.formatMediaUrl(currentMedia)"
      :mime-type="this.Helper.getMediaMimeType(currentMedia)" @close="currentMedia = null" />
    <manage-resource-form v-if="showResourceModal" :rsc="currentClass" @close="closeClassModal"
      @update="updateClassDetails" @delete="closeClassModal($event, 'delete')" @add="closeClassModal($event, 'add')" />
  </div>
</template>
  
<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";
import ManageResourceForm from "@/components/Modals/ManageResourceForm";

export default {
  components: { LoadingSpinner, MediaViewer, ManageResourceForm },
  data() {
    return {
      isLoading: false,
      resources: [],
      search: "",
      currentMedia: null,
      currentClass: null,
      showResourceModal: false,
      filterStatus: null,
      result: "",
      showJuniorModule: false,
      filter: "",
      category: "",
      moduleDetail: false,
      moduleDetail2: false
    };
  },
  computed: {
    computedresources() {
      const search = this.search.toLowerCase();

      let result = [...this.resources];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter((enquiry) =>
        enquiry.title.toLowerCase().includes(search)
      );
    },
  },
  methods: {
    openModule(title) {
      if(title == 'explorer'){
        this.moduleDetail = !this.moduleDetail
      } else if (title == 'creator'){
        this.moduleDetail2 = !this.moduleDetail2
      }
    },
    juniorModule(category) {
      this.showJuniorModule = !this.showJuniorModule

      if (this.showJuniorModule && category == 'junior') {
        this.category = 'MODULE JUNIOR'
        this.filter = 'mbot'
      } else if (this.showJuniorModule && category == 'discoverer') {
        this.category = 'MODULE DISCOVERER'
        this.filter = '3d'
      } else if (this.showJuniorModule && category == 'arduino') {
        this.category = 'MODULE EXPLORER - Arduino'
        this.filter = 'ard'
      } else if (this.showJuniorModule && category == 'iot') {
        this.category = 'MODULE EXPLORER - IOT Projects'
        this.filter = 'iot'
      } else if (this.showJuniorModule && category == 'innovator') {
        this.category = 'MODULE INNOVATOR'
        this.filter = 'mak'
      } else if (this.showJuniorModule && category == 'cyber') {
        this.category = 'MODULE CREATOR - Cyber Security'
        this.filter = 'cyber'
      } else if (this.showJuniorModule && category == 'roblox') {
        this.category = 'MODULE CREATOR - Roblox'
        this.filter = 'roblox'
      } 

      this.getresources(this.filter)
    },
    resetModule(){
      this.showJuniorModule = !this.showJuniorModule
      this.resources = []
    },
    updateClassDetails(rsc) {
      if (rsc) {
        this.currentClass = rsc;

        let classIndex = this.resources.findIndex((w) => w._id == rsc._id);

        if (classIndex > -1) {
          this.$set(this.resources, classIndex, rsc);
        }
      }
    },
    viewResource(resource, type) {
      if (!resource) return;

      if (type == "youtube" && resource.youtube_link) {
        this.currentMedia = {
          url: "https://www.youtube.com/embed/".concat(
            resource.youtube_link.replace("https://youtu.be", "")
          ),
          mime: "youtube",
        };
      } else if (type == "file" && resource.file) {
        this.currentMedia = resource.file;
      }
    },
    getresources(filter) {
      this.isLoading = true;

      this.API.get(`resources?_limit=-1&_sort=id:ASC&_where[0][title_contains]=${filter}`)
        .then((retVal) => {
          this.resources = retVal.data;
        })
        .catch((err) => { })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    // this.getresources();
  },
};
</script>